export const chatSectionNames = {
  whatsapp: 'Whatsapp',
};

export const getChatSectionsMap = () => {
  const socialTabFormSubmitButton = document.getElementById('submit-button-chat-options')!;
  const section = (socialTabFormSubmitButton) ? socialTabFormSubmitButton.dataset.section! : '';
  const name = (socialTabFormSubmitButton) ? socialTabFormSubmitButton.dataset.name! : '';

  return new Map([
    ['button-chat-whatsapp-mobile', { section: 'SECTION_ADPAGE_BOTTOM_WHATSAPP', name: chatSectionNames.whatsapp }],
    ['button-chat-line-mobile', { section: 'SECTION_ADPAGE_BOTTOM_LINE', name: 'Line' }],
    ['button-chat-messenger-mobile', { section: 'SECTION_ADPAGE_BOTTOM_MESSENGER', name: 'Messenger' }],
    ['button-chat-viber-mobile', { section: 'SECTION_ADPAGE_BOTTOM_VIBER', name: 'Viber' }],
    ['button-chat-skype-mobile', { section: 'SECTION_ADPAGE_BOTTOM_SKYPE', name: 'Skype' }],
    ['button-chat-whatsapp-desktop', { section: 'SECTION_ADPAGE_RIGHT_WHATSAPP', name: chatSectionNames.whatsapp }],
    ['button-chat-line-desktop', { section: 'SECTION_ADPAGE_RIGHT_LINE', name: 'Line' }],
    ['button-chat-messenger-desktop', { section: 'SECTION_ADPAGE_RIGHT_MESSENGER', name: 'Messenger' }],
    ['button-chat-viber-desktop', { section: 'SECTION_ADPAGE_RIGHT_VIBER', name: 'Viber' }],
    ['button-chat-skype-desktop', { section: 'SECTION_ADPAGE_RIGHT_SKYPE', name: 'Skype' }],
    ['social-tab', { section: 'SECTION_ADPAGE_BOTTOM_WHATSAPP', name: chatSectionNames.whatsapp }],
    ['social-tab-list-whatsapp', { section: 'SECTION_ADPAGE_BOTTOM_WHATSAPP', name: chatSectionNames.whatsapp }],
    ['social-tab-list-line', { section: 'SECTION_ADPAGE_BOTTOM_LINE', name: 'Line' }],
    ['social-tab-list-messenger', { section: 'SECTION_ADPAGE_BOTTOM_MESSENGER', name: 'Messenger' }],
    ['social-tab-list-viber', { section: 'SECTION_ADPAGE_BOTTOM_VIBER', name: 'Viber' }],
    ['social-tab-list-skype', { section: 'SECTION_ADPAGE_BOTTOM_SKYPE', name: 'Skype' }],
    ['social-tab-whatsapp', { section: 'SECTION_ADPAGE_BOTTOM_WHATSAPP', name: chatSectionNames.whatsapp }],
    ['social-tab-line', { section: 'SECTION_ADPAGE_BOTTOM_LINE', name: 'Line' }],
    ['social-tab-messenger', { section: 'SECTION_ADPAGE_BOTTOM_MESSENGER', name: 'Messenger' }],
    ['social-tab-viber', { section: 'SECTION_ADPAGE_BOTTOM_VIBER', name: 'Viber' }],
    ['social-tab-skype', { section: 'SECTION_ADPAGE_BOTTOM_SKYPE', name: 'Skype' }],
    ['submit-button-chat-options', { section, name }],
    ['social-tab-whatsapp-obfuscated', { section: 'SECTION_ADPAGE_DESCRIPTION_WHATSAPP', name: chatSectionNames.whatsapp }],
    ['social-tab-list-line-obfuscated', { section: 'SECTION_ADPAGE_DESCRIPTION_LINE', name: 'Line' }],
    ['social-tab-list-viber-obfuscated', { section: 'SECTION_ADPAGE_DESCRIPTION_VIBER', name: 'Viber' }],
    ['button-chat-whatsapp-image-carousel', { section: 'SECTION_ADPAGE_IMAGE_CAROUSEL_WHATSAPP', name: chatSectionNames.whatsapp }],
    ['button-chat-line-image-carousel', { section: 'SECTION_ADPAGE_IMAGE_CAROUSEL_LINE', name: 'Line' }],
    ['button-chat-messenger-image-carousel', { section: 'SECTION_ADPAGE_IMAGE_CAROUSEL_FACEBOOK', name: 'Messenger' }],
  ]);
};

export const getChatSectionIdsMap = () => {
  return new Map([
    ['SECTION_ADPAGE_BOTTOM_WHATSAPP', 4061],
    ['SECTION_ADPAGE_BOTTOM_LINE', 4062],
    ['SECTION_ADPAGE_BOTTOM_SKYPE', 4063],
    ['SECTION_ADPAGE_BOTTOM_MESSENGER', 4064],
    ['SECTION_ADPAGE_BOTTOM_VIBER', 4066],
    ['SECTION_ADPAGE_RIGHT_WHATSAPP', 4051],
    ['SECTION_ADPAGE_RIGHT_LINE', 4052],
    ['SECTION_ADPAGE_RIGHT_SKYPE', 4055],
    ['SECTION_ADPAGE_RIGHT_MESSENGER', 4053],
    ['SECTION_ADPAGE_RIGHT_VIBER', 4056],
    ['SECTION_ADPAGE_DESCRIPTION_WHATSAPP', 4403],
    ['SECTION_ADPAGE_DESCRIPTION_VIBER', 4404],
    ['SECTION_ADPAGE_DESCRIPTION_LINE', 4019],
    ['SECTION_ADPAGE_IMAGE_CAROUSEL_WHATSAPP', 4092],
  ]);
};